import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import {
  EnvironmentProviders,
  Inject,
  Injectable,
  InjectionToken,
  makeEnvironmentProviders,
  Optional,
} from '@angular/core';
import { Title } from '@angular/platform-browser';

/**
 * Returns EnvironmentProviders for Application Title Strategy. To be used in
 * the root module, or main bootstrapApplication method in a standalone app.
 *
 * Optionally, provide AG_APP_NAME with an Application Name to return full
 * expected title.
 *
 * @usageNotes
 * With AG_APP_NAME provided
 * ```
 * providers: [
 *   ...
 *   { provide: AG_APP_NAME, useValue: 'Client Portal' },
 *   provideTitleStrategy(),
 * ]
 * ```
 * Which would produce 'Page Title | Client Portal'.
 *
 * Without AG_APP_NAME provided
 * ```
 * providers: [
 *   ...
 *   provideTitleStrategy(),
 * ]
 * ```
 * Which would produce 'Page Title'.
 *
 * If Title is not set for the route, a default text of
 * 'Acacium Group Application' will be used.
 */
export function provideTitleStrategy(): EnvironmentProviders {
  return makeEnvironmentProviders([
    { provide: TitleStrategy, useClass: AcaciumGroupTitleStrategy },
  ]);
}

/**
 * DI Token for Application Name.
 *
 * @see provideTitleStrategy
 */
export const AG_APP_NAME = new InjectionToken<string>(
  'Acacium Group Application Name',
);

@Injectable()
class AcaciumGroupTitleStrategy extends TitleStrategy {
  constructor(
    private readonly _titleService: Title,
    @Optional() @Inject(AG_APP_NAME) private readonly _appName: string | null,
  ) {
    super();
  }

  public override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    if (title && this._appName) {
      this._titleService.setTitle(`${title} | ${this._appName}`);
      return;
    }
    if (title && !this._appName) {
      this._titleService.setTitle(`${title}`);
      return;
    }
    if (!title && this._appName) {
      this._titleService.setTitle(`${this._appName}`);
      return;
    }
    this._titleService.setTitle(`Acacium Group Application`);
  }
}
