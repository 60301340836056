import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@client-portal/routing';
import { ToastService } from '@client-portal/services/toast';
import { ToastType } from '@client-portal/enums/common';
import { LogOutService } from '@client-portal/auth/services';

// TODO: Implement tests once error logic is put in place [cas@25/10/2023]

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _toastService: ToastService,
    private _router: Router,
    private _authService: LogOutService,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.error) {
          switch (error.status) {
            case 403: {
              void this._router.navigate([ROUTE_PATHS.accessDenied]);
              break;
            }
            case 404:
              void this._router.navigate(['**']);
              return throwError(() => error);
            case 401:
              this._authService.logOut();
              this._router
                .navigate([ROUTE_PATHS.unauthorized])
                .then(() => window.location.reload());
              return throwError(() => error);
            case 500:
              const toastConfig = {
                message:
                  'Sorry, the application ran into an unexpected error. Please refresh the page and try again.',
                title: 'Unexpected Error',
                type: ToastType.Error,
              };
              this._toastService.showToast(toastConfig);
          }
        }
        console.error('unknown error', error);

        return throwError(() => error);
      }),
    );
  }
}
