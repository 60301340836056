import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { A11yElementClickDirective } from '@client-portal/directives/a11y-element-click';
import { NavigationService } from '@client-portal/services/navigation';
import { NavigationItemsComponent } from '@client-portal/components/navigation-items';
import { IconComponent } from '@client-portal/ui/icon';

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    RouterLink,
    RouterLinkActive,
    NavigationItemsComponent,
    A11yElementClickDirective,
    AsyncPipe,
    IconComponent,
    NgOptimizedImage,
  ],
  standalone: true,
})
export class HeaderComponent {
  public isAuthenticated$ = this._authService.isAuthenticated$;

  constructor(
    public navigation: NavigationService,
    private _authService: AuthService,
  ) {}
}
