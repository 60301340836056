import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorDefaultOptions,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  EnvironmentProviders,
  Injectable,
  makeEnvironmentProviders,
} from '@angular/core';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
} from '@angular/material/checkbox';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MAT_DIALOG_SCROLL_STRATEGY,
  MatDialogConfig,
} from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
  MatSlideToggleDefaultOptions,
} from '@angular/material/slide-toggle';
import {
  MAT_RADIO_DEFAULT_OPTIONS,
  MatRadioDefaultOptions,
} from '@angular/material/radio';

// By default, paginator shows tooltip on arrows hover
// This class hides the tooltip by overriding the labels
@Injectable()
class HidePageLabels extends MatPaginatorIntl {
  public override firstPageLabel = '';
  public override lastPageLabel = '';
  public override nextPageLabel = '';
  public override previousPageLabel = '';
}

const MATERIAL_DEFAULT_OPTIONS = [
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'outline',
      floatLabel: 'auto',
      color: 'primary',
      hideRequiredMarker: false,
      subscriptSizing: 'dynamic',
    },
  },
  {
    provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
    useValue: {
      hidePageSize: true,
    } as MatPaginatorDefaultOptions,
  },
  {
    provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
    useValue: {
      color: 'primary',
    } as MatCheckboxDefaultOptions,
  },
  {
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: {
      color: 'primary',
    } as MatRadioDefaultOptions,
  },
  {
    provide: MatPaginatorIntl,
    useClass: HidePageLabels,
  },
  {
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: {
      color: 'primary',
    } as MatRadioDefaultOptions,
  },
  {
    provide: MAT_DIALOG_SCROLL_STRATEGY,
    useFactory: (scrollStrategyOptions: ScrollStrategyOptions) =>
      scrollStrategyOptions.block,
    deps: [ScrollStrategyOptions],
  },
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      disableClose: true,
      autoFocus: false,
    } as MatDialogConfig,
  },
  {
    provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
    useValue: {
      hideIcon: true,
    } as MatSlideToggleDefaultOptions,
  },
];

export function provideMaterialDefaults(): EnvironmentProviders {
  return makeEnvironmentProviders(MATERIAL_DEFAULT_OPTIONS);
}
