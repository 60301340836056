import { BrowserConfig } from '@bugsnag/browser';
import BugsnagPerformance, {
  BrowserConfiguration,
} from '@bugsnag/browser-performance';
import { ErrorHandler, Injectable } from '@angular/core';
import Bugsnag, { Client } from '@bugsnag/js';
import { ClientPortalEnvironment } from '@client-portal/models/environment';
import { environment } from '../environment/environments/environment';
import packageJson from '../package.json';

const BUGSNAG_API_KEY = 'f855c773ae5f219e8649d89d7f79de08' as const;
const BUGSNAG_ENABLED_STAGES: Array<
  ClientPortalEnvironment['bugsnagReleaseStage']
> = ['Development', 'Test', 'Integration', 'Production'];

export const BUGSNAG_BROWSER_CONFIG: BrowserConfig = {
  apiKey: BUGSNAG_API_KEY,
  releaseStage: environment.bugsnagReleaseStage,
  appVersion: packageJson.version,
  enabledReleaseStages: BUGSNAG_ENABLED_STAGES,
};
export const BUGSNAG_PERFORMANCE_CONFIG: BrowserConfiguration = {
  apiKey: BUGSNAG_API_KEY,
  releaseStage: environment.bugsnagReleaseStage,
  appVersion: packageJson.version,
  enabledReleaseStages: BUGSNAG_ENABLED_STAGES,
};

/* Workaround for esbuild migration
   Current implementation of bugsnag error handler is not compatible with esbuild
   This is a temporary workaround to fix the issue
   
   ref issue: https://github.com/bugsnag/bugsnag-js/issues/2144 
   
   If this issue is resolved, we can remove this workaround and use the original implementation
*/
@Injectable()
export class CustomBugsnagErrorHandler implements ErrorHandler {
  public bugsnagClient!: Client;

  constructor() {
    const client = Bugsnag.start(BUGSNAG_BROWSER_CONFIG);
    BugsnagPerformance.start(BUGSNAG_PERFORMANCE_CONFIG);

    if (client) {
      this.bugsnagClient = client;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleError(error: any): void {
    const handledState = {
      severity: 'error',
      severityReason: { type: 'unhandledException' },
      unhandled: true,
    };

    const event = this.bugsnagClient.Event.create(
      error,
      true,
      handledState,
      'angular error handler',
      1,
    );

    if (error.ngDebugContext) {
      event.addMetadata('angular', {
        component: error.ngDebugContext.component,
        context: error.ngDebugContext.context,
      });
    }

    console.error(error);
    this.bugsnagClient._notify(event);
  }
}
