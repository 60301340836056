import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ROUTE_PATHS } from '@client-portal/routing';
import { DeviceService } from '@client-portal/services/device';
import { NavigationService } from '@client-portal/services/navigation';
import { SwitchModalComponent } from '@client-portal/components/switch-modal';
import { DivisionService } from '@client-portal/services/division';
import { AllowedShiftTypeService } from '@client-portal/services/allowed-shift-type';
import { IconComponent } from '@client-portal/ui/icon';
import { FeatureFlagsService } from '@client-portal/services/feature-flags';

interface MenuItem {
  routerLink?: string;
  iconName: string;
  label: string;
}

@Component({
  selector: 'clp-navigation-items',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, IconComponent],
  templateUrl: './navigation-items.component.html',
  styleUrls: ['./navigation-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemsComponent {
  private _navOptions: Array<MenuItem> = [
    // {
    //   routerLink: `/${ROUTE_PATHS.notifications}`,
    //   iconName: 'bell',
    //   label: 'Notifications',
    // },
    {
      routerLink: `/${ROUTE_PATHS.currentOrders.root}`,
      iconName: 'trip',
      label: 'Current orders',
    },
    {
      routerLink: `/${ROUTE_PATHS.orderHistory.root}`,
      iconName: 'history',
      label: 'Order history',
    },
    {
      routerLink: `/${ROUTE_PATHS.statistics}`,
      iconName: 'bar_chart_4_bars',
      label: 'Statistics',
    },
    {
      routerLink: `/${ROUTE_PATHS.helpAndSupport.root}`,
      iconName: 'help',
      label: 'Help and support',
    },
    {
      routerLink: `/${ROUTE_PATHS.profile.root}`,
      iconName: 'person',
      label: 'Profile',
    },
    {
      routerLink: `/${ROUTE_PATHS.administration.root}`,
      iconName: 'edit_square',
      label: 'Administration',
    },
    {
      iconName: 'sync_alt',
      label: 'Switch account',
    },
    {
      routerLink: `/${ROUTE_PATHS.orderTypes}`,
      iconName: 'list_alt',
      label: 'Order types',
    },
    {
      routerLink: `/${ROUTE_PATHS.features}`,
      iconName: 'interests',
      label: 'Our features',
    },
    {
      routerLink: `/${ROUTE_PATHS.logout}`,
      iconName: 'logout',
      label: 'Log out',
    },
  ];
  public readonly options = computed(() => {
    let filteredOptions = this._navOptions;
    if (
      this._featureFlags.values.hideOrderTypes() ||
      this._allowedShiftType.shortNoticeOrAdvance()
    ) {
      filteredOptions = this._navOptions.filter(
        (option) => option.routerLink !== `/${ROUTE_PATHS.orderTypes}`,
      );
    }
    if (this._featureFlags.values.hideOurFeatures()) {
      filteredOptions = this._navOptions.filter(
        (option) => option.routerLink !== `/${ROUTE_PATHS.features}`,
      );
    }
    return filteredOptions;
  });

  constructor(
    public device: DeviceService,
    public divisionService: DivisionService,
    private _featureFlags: FeatureFlagsService,
    private _navigation: NavigationService,
    private _dialog: MatDialog,
    private _allowedShiftType: AllowedShiftTypeService,
  ) {}

  public toggleMenu(): void {
    this._navigation.toggleMobileMenu();
  }

  public openSwitchDialog(): void {
    this._dialog.open(SwitchModalComponent, {
      width: '70vw',
    });
  }
}
