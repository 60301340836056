import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DivisionService } from '@client-portal/services/division';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  constructor(private _divisionService: DivisionService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const headers: { [key: string]: string } = {};

    if (this._divisionService.division()) {
      headers['x-company'] = this._divisionService.division();
    }

    if (request.method === 'PATCH') {
      headers['Content-Type'] = 'application/json-patch+json';
    }
    const authRequest = request.clone({
      setHeaders: headers,
    });

    return next.handle(authRequest);
  }
}
