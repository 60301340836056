import { computed, Injectable, signal } from '@angular/core';
import { UserService } from '@client-portal/services/user';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public menuIconsHidden = computed(() => !this._userService.isAccountActive());
  public mobileMenuVisible = signal(false);

  constructor(private _userService: UserService) {}

  public toggleMobileMenu(): void {
    this.mobileMenuVisible.update((value) => !value);
  }
}
