import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { HeaderComponent } from '@client-portal/ui/header';
import { NavigationComponent } from '@client-portal/components/navigation';
import { ToastMessageComponent } from '@client-portal/ui/toast-message';
import { ProgressSpinnerComponent } from '@client-portal/ui/progress-spinner';
import { DeviceService } from '@client-portal/services/device';
import { ToastService } from '@client-portal/services/toast';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LogOutService } from '@client-portal/auth/services';
import { NavigationService } from '@client-portal/services/navigation';
import { ClpAuthService } from '@client-portal/services/auth';
import { LoadingOverlayService } from '@client-portal/services/loading-overlay';
import { LiveChatWidgetModule } from '@livechat/widget-angular';
import { ClpEnvironment } from '@client-portal/environment';
import { ChatWidgetService } from '@client-portal/services/chat-widget';
import { ErrorComponent } from './features/misc';

@Component({
  selector: 'clp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterOutlet,
    HeaderComponent,
    NavigationComponent,
    ToastMessageComponent,
    ErrorComponent,
    ProgressSpinnerComponent,
    LiveChatWidgetModule,
  ],
  standalone: true,
})
export class AppComponent implements OnInit {
  constructor(
    public clpAuthService: ClpAuthService,
    public device: DeviceService,
    public navigation: NavigationService,
    public toastService: ToastService,
    public chatService: ChatWidgetService,
    public loadingOverlay: LoadingOverlayService,
    public clpEnvironment: ClpEnvironment,
    private _logOutService: LogOutService,
    private _idle: Idle,
  ) {
    this._setupIdleConfig();
  }

  public ngOnInit(): void {
    this._idle.watch(); //this method to start/reset the idle process
  }

  private _setupIdleConfig(): void {
    this._idle.setIdle(3600); // how long can they be inactive before considered idle, in seconds
    this._idle.setTimeout(1); // how long can they be idle before considered timed out, in seconds
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    this._idle.onTimeout.pipe(takeUntilDestroyed()).subscribe(() => {
      this._logOutService.logOut();
    });
  }
}
