{
  "name": "client-portal",
  "version": "1.2.0",
  "contributors": [
    {
      "email": "connor.smith@acaciumgroup.com",
      "name": "Connor Smith"
    },
    {
      "email": "miri.joo@acaciumgroup.com",
      "name": "Miri Joo"
    },
    {
      "email": "priya.ragahvan@acaciumgroup.com",
      "name": "Priya Raghavan"
    },
    {
      "email": "rafal.szczuka@acaciumgroup.com",
      "name": "Rafał Szczuka"
    }
  ],
  "repository": {
    "type": "git",
    "url": "https://dev.azure.com/ics-development/TNS/_git/client-portal"
  },
  "license": "ISC",
  "scripts": {
    "prepare": "husky",
    "commit": "cz",
    "start": "ng serve",
    "lint": "eslint {**/*,*}.{js,ts,html}",
    "lint:fix": "eslint {**/*,*}.{js,ts,html} --fix",
    "prettier": "prettier {**/*,*}.{html,js,ts,md,scss,css}",
    "prettier:fix": "prettier {**/*,*}.{html,js,ts,md,scss,css} --write",
    "stylelint": "stylelint {**/*,*}.{scss,css}",
    "stylelint:fix": "stylelint {**/*,*}.{scss,css} --fix",
    "test": "jest -c jest.config.ts --silent",
    "test:watch": "npm run test -- --watch",
    "test:cc": "npm run test -- --coverage",
    "test:cc:since-main": "npm run test:cc -- --changedSince origin/main",
    "build": "ng build client-portal",
    "build:dev": "npm run build -- --configuration dev",
    "build:test": "npm run build -- --configuration test",
    "build:integration": "npm run build -- --configuration int",
    "build:prod": "npm run build -- --configuration prod",
    "build:local:stats": "npm run build -- --stats-json",
    "build:dev:stats": "npm run build -- --configuration dev --stats-json",
    "build:test:stats": "npm run build -- --configuration test --stats-json",
    "build:integration:stats": "npm run build -- --configuration int --stats-json",
    "build:prod:stats": "npm run build -- --configuration prod --stats-json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "17.1.2",
    "@angular/cdk": "17.1.2",
    "@angular/common": "17.1.2",
    "@angular/compiler": "17.1.2",
    "@angular/core": "17.1.2",
    "@angular/forms": "17.1.2",
    "@angular/material": "17.1.2",
    "@angular/platform-browser": "17.1.2",
    "@angular/platform-browser-dynamic": "17.1.2",
    "@angular/pwa": "17.1.2",
    "@angular/router": "17.1.2",
    "@angular/service-worker": "17.1.2",
    "@auth0/auth0-angular": "2.2.3",
    "@bugsnag/browser-performance": "2.5.0",
    "@bugsnag/js": "7.23.0",
    "@bugsnag/plugin-angular": "7.23.0",
    "@livechat/widget-angular": "1.3.3",
    "@ng-idle/core": "14.0.0",
    "@ng-idle/keepalive": "14.0.0",
    "@ngneat/input-mask": "6.1.0",
    "awesome-phonenumber": "6.4.0",
    "dayjs": "1.11.10",
    "ngx-skeleton-loader": "9.0.0",
    "rfc6902": "5.1.1",
    "rfdc": "1.3.1",
    "rxjs": "7.8.1",
    "tslib": "2.6.2",
    "zone.js": "0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.1.2",
    "@angular-devkit/core": "17.1.2",
    "@angular-devkit/schematics": "17.1.2",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "17.1.2",
    "@angular/compiler-cli": "17.1.2",
    "@angular/language-service": "17.1.2",
    "@commitlint/cli": "18.6.0",
    "@commitlint/config-conventional": "18.6.0",
    "@commitlint/cz-commitlint": "18.6.0",
    "@schematics/angular": "17.1.2",
    "@types/ejs": "3.1.5",
    "@types/inputmask": "5.0.7",
    "@types/jest": "29.5.12",
    "@types/node": "20.11.0",
    "@typescript-eslint/eslint-plugin": "6.21.0",
    "@typescript-eslint/parser": "6.21.0",
    "commitizen": "4.3.0",
    "ejs": "3.1.9",
    "eslint": "8.56.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-rxjs": "5.0.3",
    "glob": "10.3.10",
    "husky": "9.0.10",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-preset-angular": "14.0.0",
    "lint-staged": "15.2.2",
    "modern-css-reset": "1.4.0",
    "ng-mocks": "14.12.1",
    "normalize.css": "8.0.1",
    "prettier": "3.2.5",
    "stylelint": "16.2.1",
    "stylelint-config-standard": "36.0.0",
    "stylelint-config-standard-scss": "13.0.0",
    "ts-jest": "29.1.2",
    "ts-node": "10.9.2",
    "typescript": "5.3.3"
  },
  "config": {
    "commitizen": {
      "path": "@commitlint/cz-commitlint"
    }
  },
  "engines": {
    "node": "20.11.0",
    "npm": "10.2.4"
  }
}
