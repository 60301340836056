import { computed, Injectable, signal } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from '@client-portal/services/user';
import {
  catchError,
  combineLatest,
  filter,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ClpEnvironment } from '@client-portal/environment';
import { GoogleTagManagerService } from '@client-portal/services/google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class ClpAuthService {
  private _userProfileError = signal<Error | undefined>(undefined);
  private _authError = toSignal(
    this._authService.error$.pipe(
      filter((error) => !this._isLoginRequiredError(error)),
    ),
  );

  // combine auth and get user profile - to display global loader until both are resolved
  private _isAuthenticated$ = combineLatest([
    this._authService.isAuthenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          // set GTM in DOM only after auth0 is authenticated
          return this._setGTM$();
        }
        return of(isAuthenticated);
      }),
    ),
    this._userService.userProfile$.pipe(
      catchError((e) => {
        if (!this._isLoginRequiredError(e)) {
          this._userProfileError.set(e);
        }
        return of(null);
      }),
    ),
  ]).pipe(
    map(([isAuthenticated, userProfile]) => isAuthenticated && !!userProfile),
  );

  public error = computed(() => this._authError() || this._userProfileError());
  public isAuthenticated = toSignal(this._isAuthenticated$);

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _router: Router,
    private _env: ClpEnvironment,
    private _gtmService: GoogleTagManagerService,
  ) {
    this._onAppStateChange();
  }

  private _onAppStateChange(): void {
    this._authService.appState$
      .pipe(takeUntilDestroyed())
      .subscribe((state) => {
        if (state.target) {
          void this._router.navigateByUrl(state.target);
        }
      });
  }

  private _setGTM$(): Observable<boolean> {
    this._gtmService.setConfig({
      id: this._env.gtmId,
    });
    return this._gtmService.addGtmToDom$().pipe(map(() => true));
  }

  private _isLoginRequiredError(error: Error): boolean {
    /* Check if error contains 'login required' message
     * to avoid displaying this particular error to the user
     */
    return !!error.message.match(/login required/i);
  }
}
