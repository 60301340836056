import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { NavigationItemsComponent } from '@client-portal/components/navigation-items';
import { NavigationService } from '@client-portal/services/navigation';

@Component({
  selector: 'clp-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    NavigationItemsComponent,
    AsyncPipe,
    NgOptimizedImage,
  ],
})
export class NavigationComponent {
  public isAuthenticated$ = this._authService.isAuthenticated$;
  constructor(
    private _authService: AuthService,
    public navigation: NavigationService,
  ) {}
}
