import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, provideAuth0 } from '@auth0/auth0-angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { provideServiceWorker } from '@angular/service-worker';
import { appRoutes } from '@client-portal/routing';
import { MatNativeDateModule } from '@angular/material/core';
import {
  AG_APP_NAME,
  provideTitleStrategy,
} from '@client-portal/providers/title';
import { ErrorInterceptor } from '@client-portal/interceptors/error';
import { HttpHeadersInterceptor } from '@client-portal/interceptors/http-headers';
import { provideMaterialDefaults } from '@client-portal/providers/material-default-options';
import { environment } from '../environment/environments/environment';
import { CustomBugsnagErrorHandler } from './bugsnag.config';

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    { provide: AG_APP_NAME, useValue: 'Client Portal' },
    {
      provide: ErrorHandler,
      useClass: CustomBugsnagErrorHandler,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeadersInterceptor,
      multi: true,
    },
    provideMaterialDefaults(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(appRoutes),
    provideAuth0({
      clientId: environment.auth0.clientId,
      domain: environment.auth0.domain,
      cacheLocation: 'localstorage',
      authorizationParams: {
        audience: environment.apiEndpoint,
        redirect_uri: environment.auth0.redirectUrl,
      },
      errorPath: environment.auth0.errorPath,
      useRefreshTokens: true,
      // https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/18
      // As we are using custom-domains for everything now, this is OK to turn on.
      useRefreshTokensFallback: true,
      httpInterceptor: {
        allowedList: [`${environment.apiEndpoint}/*`],
      },
    }),
    provideTitleStrategy(),
    provideAnimations(),
    importProvidersFrom(NgIdleKeepaliveModule.forRoot(), MatNativeDateModule),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.pwa,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
