<header class="ui-header">
  @if (isAuthenticated$ | async) {
    @if (!navigation.menuIconsHidden()) {
      <button
        (click)="navigation.toggleMobileMenu()"
        class="ui-header__burger"
        mat-icon-button
      >
        <ui-icon class="ui-header__burger-icon" icon="menu" />
      </button>
    }
  }
  <a aria-label="Home" class="ui-header__logo" routerLink="/">
    <img
      ngSrc="assets/svg/logo.svg"
      height="40"
      width="40"
      alt="tns-logo"
      priority
    />
  </a>
</header>
<nav [class.show]="navigation.mobileMenuVisible()" class="clp-mobile-nav">
  <div class="clp-mobile-nav__header">
    <ui-icon
      icon="close"
      (uiA11yElementClick)="navigation.toggleMobileMenu()"
      aria-label="Close menu"
      class="icon-close"
      tabindex="0"
    />
    <a
      (uiA11yElementClick)="navigation.toggleMobileMenu()"
      aria-label="Home"
      class="ui-header__logo"
      routerLink="/"
    >
      <img
        ngSrc="assets/svg/logo.svg"
        height="40"
        width="40"
        alt="tns-logo"
        priority
      />
    </a>
  </div>

  <clp-navigation-items />
</nav>
<div
  (uiA11yElementClick)="navigation.toggleMobileMenu()"
  [class.show]="navigation.mobileMenuVisible()"
  class="backdrop"
  tabindex="0"
></div>
