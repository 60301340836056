<nav class="clp-nav">
  <a aria-label="Home" class="clp-nav__logo" routerLink="/">
    <img
      ngSrc="assets/svg/logo.svg"
      height="40"
      width="40"
      alt="tns-logo"
      priority
    />
  </a>
  @if (isAuthenticated$ | async) {
    @if (!navigation.menuIconsHidden()) {
      <clp-navigation-items class="clp-nav__container" />
    }
  }
</nav>
