import { Inject, Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { GoogleTag, GoogleTagManagerConfig } from './google-tag-manager-config';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private readonly _gtmTagId = 'GTMscript';
  private readonly _gtmNoScriptId = 'gtm-noscript';
  private readonly _gtmNoScriptIFrameId = 'gtm-noscript-iframe';

  private _config: GoogleTagManagerConfig = { id: null };

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  private get _hasBeenAddedToDom(): boolean {
    return !!this._document.getElementById(this._gtmTagId);
  }

  public addGtmToDom$(): Observable<boolean> {
    return new Observable<boolean>((observer: Subscriber<boolean>) => {
      if (!this._config.id) {
        observer.error('Google Tag Manager ID not provided.');
        return;
      }
      if (this._hasBeenAddedToDom) {
        observer.error('Google Tag Manager has already been instantiated.');
        return;
      }

      this._pushOnDataLayer({
        gtm: { start: new Date().getTime() },
        event: 'gtm.js',
      });

      const gtmScript = this._document.createElement('script');

      gtmScript.id = this._gtmTagId;
      gtmScript.async = true;
      gtmScript.src = this._applyGtmQueryParams(
        this._config.gtm_resource_path
          ? this._config.gtm_resource_path
          : 'https://www.googletagmanager.com/gtm.js',
      );
      gtmScript.addEventListener('load', () => {
        observer.next(true);
        observer.complete();
      });
      gtmScript.addEventListener('error', (err) => observer.error(err));

      if (this._config.gtm_nonce) {
        gtmScript.setAttribute('nonce', this._config.gtm_nonce);
      }

      this._document.head.insertBefore(
        gtmScript,
        this._document.head.firstChild,
      );

      const noscript = this._document.createElement('noscript');
      const iframe = this._document.createElement('iframe');

      iframe.id = this._gtmNoScriptIFrameId;
      iframe.setAttribute(
        'src',
        `https://www.googletagmanager.com/ns.html?id=${this._config.id}`,
      );
      iframe.style.width = '0';
      iframe.style.height = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';

      noscript.id = this._gtmNoScriptId;
      noscript.appendChild(iframe);

      this._document.body.appendChild(noscript);
    });
  }

  public setConfig(config: GoogleTagManagerConfig): void {
    if (!this._config) {
      this._config = { id: null };
    }

    this._config = {
      ...this._config,
      ...config,
    };
  }

  private _getDataLayer(): Array<GoogleTag> | null {
    const window = this._document.defaultView;

    if (!window) {
      return null;
    }

    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  private _pushOnDataLayer(obj: GoogleTag): void {
    const dataLayer = this._getDataLayer();

    if (!dataLayer) {
      throw new Error('Unable to add GTM Tag. No window object provided.');
    }

    dataLayer.push(obj);
  }

  private _applyGtmQueryParams(baseUrl: string): string {
    const keys = Object.keys(this._config);
    const values = Object.values(this._config);
    const queryParams: Array<string> = [];

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = values[i];

      if (key && value) {
        queryParams.push(`${key}=${value}`);
      }
    }
    if (baseUrl.indexOf('?') === -1 && queryParams.length > 0) {
      baseUrl += '?';
    }
    return `${baseUrl}${queryParams.join('&')}`;
  }
}
