<div class="clp-nav-items">
  @for (item of options(); track $index) {
    @if (
      item.iconName !== 'sync_alt' || divisionService.hasMultipleDivisions()
    ) {
      <a
        (click)="
          item.iconName === 'sync_alt'
            ? openSwitchDialog()
            : device.isMobile() && toggleMenu()
        "
        [attr.aria-label]="item.label"
        [routerLink]="item.routerLink"
        class="clp-nav-items__link"
        routerLinkActive="icon-nav--active"
      >
        <div class="icon-nav">
          <ui-icon [icon]="item.iconName" />
        </div>
        @if (device.isMobile()) {
          <span>{{ item.label }}</span>
        }
      </a>
    }
  }
</div>
