import { bootstrapApplication } from '@angular/platform-browser';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { WeekDay } from '@angular/common';
import { AppComponent } from './app/app.component';
import { APP_CONFIG } from './app.config';

// Could cause some issues if used in a place that week start
// is not Monday.
// An assumption has been made that that won't happen
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: WeekDay.Monday,
});

bootstrapApplication(AppComponent, APP_CONFIG).catch((err) =>
  console.error(err),
);
