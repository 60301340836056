@if (clpAuthService.isAuthenticated(); as isAuthenticated) {
  @if (isAuthenticated) {
    <div class="clp-root">
      @if (device.isMobile()) {
        <ui-header />
      }

      <div class="clp-root__wrapper">
        @if (!device.isMobile()) {
          <clp-navigation />
        }
        <main class="clp-root__main">
          <router-outlet />
        </main>
      </div>
    </div>
    <livechat-widget
      [license]="clpEnvironment.chatLicenseId"
      [visibility]="chatService.chatVisible()"
      (onVisibilityChanged)="chatService.handleVisibilityChanged($event)"
    ></livechat-widget>
  }

  @if (toastService.toast(); as toast) {
    <ui-toast-message [toastConfig]="toast" />
  }
} @else {
  @if (clpAuthService.error(); as error) {
    <clp-error [error]="error" />
  } @else {
    <ui-progress-spinner />
  }
}

@if (loadingOverlay.loading()) {
  <ui-progress-spinner />
}
